<template>
  <div class="action-list">
    <h2 class="action-list__title">Action List</h2>
    <div class="action-list__wrap">
      <h3 class="action-list__subtitle">Action List Default</h3>
      <ActionsList :actionList="actionList" />
    </div>
    <div class="action-list__wrap">
      <h3 class="action-list__subtitle">Action List Props</h3>
      <ul class="action-list__list">
        <li class="action-list__item">
          <span>actionList</span> - массив с объектами, содержащими информацию о пунктах action
          list:
        </li>
        <li class="action-list__item">
          <pre>
            {
                <span>isShow</span> - показать/скрыть иконку
                <span>iconType</span> - название иконки
                <span>actionText</span> - назавние пункта action list
                <span>actionType</span> - название действия, которое будет выполнятся при клике на пункт action list
            }
          </pre>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";

export default {
  name: "ActionListPage",
  mixins: [],
  props: {
    value: {
      type: String
    }
  },
  components: { ActionsList },
  data() {
    return {
      actionList: [
        {
          isShow: true,
          iconType: "circle-plus",
          actionText: "Добавить в квартал",
          actionType: "Add"
        },
        {
          isShow: true,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: "Edit"
        },
        {
          isShow: true,
          iconType: "archive",
          actionText: "Архивировать",
          actionType: "Archive"
        },
        {
          isShow: true,
          iconType: "download",
          actionText: "Выгрузить",
          actionType: "Download"
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: "Delete"
        }
      ]
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>
<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.action-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
